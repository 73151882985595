import { render, staticRenderFns } from "./Payable.vue?vue&type=template&id=875e0ee8&scoped=true"
import script from "./Payable.vue?vue&type=script&lang=js"
export * from "./Payable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "875e0ee8",
  null
  
)

export default component.exports